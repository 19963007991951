<template>
  <div class="widgetContainer widgetContainer--center widgetContainer--scrollable bg-white ownerDetails">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="goToPreviousScreen" />
        <p class="title">
          {{ $t('kyc_address_NavTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form
        class="wise-form"
        ref="personAddress"
        :model="formData"
        :rules="formRules">
        <div class="el-input--full-width el-input">
          <el-form-item
            prop="address.line1"
            :rules="formRules.line1"
            class="is-no-asterisk"
            :label="$t('address_1')">
            <el-input style="display: none" />
            <el-input v-model="formData.address.line1" style="display: none" />
            <div class="el-input">
              <gmap-autocomplete
                :placeholder="$t('address_1')"
                autocomplete="disabled"
                :value="formData.address.line1"
                :options="{
                  componentRestrictions: { country: country }
                }"
                @change.native="formData.address.line1 = $event.target.value"
                @place_changed="updateAddress($event)"
                :select-first-on-enter="true"
                class="el-input__inner" />
            </div>
          </el-form-item>
        </div>

        <el-form-item prop="address.line2" :label="$t('address_2')">
          <el-input
            :placeholder="$t('address_2')"
            v-model="formData.address.addressLine2" />
        </el-form-item>
        <div class="wise-form__inline">
          <el-form-item
            prop="address.city"
            class="is-no-asterisk"
            :rules="formRules.city"
            :label="$t('address_City')">
            <el-input
              style="flex: 2"
              :placeholder="$t('address_City')"
              v-model="formData.address.city" />
          </el-form-item>
          <el-form-item
            prop="address.country"
            class="is-no-asterisk"
            :rules="formRules.state"
            :label="$t('address_State')">
            <el-input
              style="flex: 1"
              :placeholder="$t('address_State')"
              v-model="formData.address.state" />
          </el-form-item>
          <el-form-item
            prop="address.postalCode"
            class="is-no-asterisk"
            :rules="formRules.postalCode"
            :label="$t('address_Zipcode')">
            <el-input
              style="flex: 1"
              :placeholder="$t('address_Zipcode')"
              v-model="formData.address.postalCode" />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="widgetContainer__footer--fixed">
      <el-button type="primary" :disabled="isFormValid" class="el-button__brand brand" @click="next()">
        {{ $t('address_NavRightButton_Title') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { formatGoogleMapData } from '@/utils/gmap';
import { mapGetters, mapMutations } from 'vuex';
import { getCountryDetailsFromPhoneNumber } from '@/utils/env';

export default {
  name: 'OwnerAddress',
  data() {
    return {
      formData: {
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          country: 'US',
          addressType: 'mailing',
          state: null
        }
      },
      formRules: {
        line1: [
          {
            required: true,
            message: 'Address 1 is required'
          }
        ],
        city: [
          {
            required: true,
            message: 'City is required'
          }
        ],
        state: [
          {
            required: true,
            message: 'State is required'
          }
        ],
        postalCode: [
          {
            required: true,
            message: 'Zip Code is required'
          }
        ]
      }
    };
  },
  created() {
    if (this.getCreatePageOwnersDetail === null) {
      this.$router.push('/kyb/ownersList');
    } else if (
      this.getCreatePageOwnersDetail.person &&
      this.getCreatePageOwnersDetail.person.address
    ) {
      this.formData.address = this.getCreatePageOwnersDetail.person.address;
    }
  },
  computed: {
    ...mapGetters('kyb', ['getCreatePageOwnersDetail']),
    ...mapGetters('user', ['getPersonDetails']),
    isFormValid() {
      if (
        this.formData.address.line1 &&
        this.formData.address.city &&
        this.formData.address.postalCode
      ) {
        return false;
      } else {
        return true;
      }
    },
    country() {
      /** @type {import('@/modules/user/store/state').userState['personDetails']} */
      const person = this.getPersonDetails;
      const details = getCountryDetailsFromPhoneNumber(person.phone);
      if(details && details.code)
        return details.code;
      
      return 'US'

    }
  },
  methods: {
    ...mapMutations('kyb', ['setCreatePageOwnerDetails']),
    updateAddress(e) {
      const address = formatGoogleMapData(e);
      this.formData.address = address;
    },
    next() {
      this.$refs.personAddress.validate((valid) => {
        if (!valid) return;

        /** @type {import('@m/kyb/store/state').kybState['createPageOwnerDetails']} */
        const owner = {
          ...this.getCreatePageOwnersDetail
        };
        const { line1, line2, city, postalCode, state } =
          this.formData.address;
        const country = this.country;
        owner.person.address = {
          line1,
          line2,
          city,
          postalCode,
          country,
          addressType: 'mailing',
          state
        };

        this.setCreatePageOwnerDetails(owner);
        this.$router.push('/kyb/ownerOwnership');
      });
    }
  }
};
</script>
<style lang="scss">
.ownerDetails {
  .widgetContainer__body {
    margin-bottom: 50px;
    overflow-y: auto;
  }
}
</style>
